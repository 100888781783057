import { useFormContext, Controller } from "react-hook-form";
import { useAppConfigValue } from "../App";
import { SubscriptionFormContext } from "./SubscriptionForm";
import { useContext } from "react";
export const SubscribeToMailingList = () => {
  const { offer }: any = useContext(SubscriptionFormContext);
  const { publicationName }: any = useAppConfigValue();
 
  const { control,watch, register, formState: { errors, isSubmitting } } = useFormContext();
  const label = `Also subscribe to ${publicationName}'s free ${offer?.mailingList ? offer?.mailingList: ""} e-mail list`; //`I agree to receive information and commercial offers from ${publicationName}`
  return (
    <div className="flex row mt-6">
      <fieldset disabled={isSubmitting} className="space-y-5">
        <Controller
          rules={{ required: false }}
          control={control}
          name="addToMailingList"
          render={({ field }) => {
            const { name, onBlur, onChange, value } = field;
            return (
              <div>
                <legend className="sr-only">{label}</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      aria-describedby="terms"
                      title={label}
                      name={name}
                      onChange={onChange}
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={value}/>
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="terms" className="font-medium text-gray-700"> {label} </label>
                  </div>
                </div>
              </div>
            );
          }} />

      </fieldset>
    </div>

  );
};

/* <p id="comments-description" className="text-gray-500">
            <span>To learn more about how your data will be used please refer to our </span>
            <a className="font-bold underline" href={privacyPolicyUrl} target="_blank">privacy policy</a>
          </p>
          */
/*
            <Switch checked={value} onChange={onChange} className={`${value ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 mr-2  items-center rounded-full`}>
              <span className="sr-only">{label}</span>
              <span className={`${value ? 'translate-x-6' : 'translate-x-1' } inline-block h-4 w-4 transform rounded-full bg-white transition`}></span>
            </Switch>*/