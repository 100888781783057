import React, {useEffect} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { RadioGroup } from '@headlessui/react';
import { classNames } from "./SubscriptionForm";

export const BillingAddressSameAsDelivery = () => {
  const { control, formState: { isSubmitting}, watch, setValue } = useFormContext();
  const giftSubscription = watch("giftSubscription");
  useEffect(() => {
    if (giftSubscription) {
      setValue("billingAddressSameAsDelivery", "No");
    }
  }, [giftSubscription])
  
  return (
    <fieldset disabled={isSubmitting} className="mt-6">
      <legend className="block text-sm  font-medium text-gray-700">Billing address</legend>
      <Controller
        rules={{ required: true }}
        control={control}
        name="billingAddressSameAsDelivery"
        render={({ field }) => {
          const { name, onBlur, onChange, value } = field;
          return (
            <RadioGroup disabled={isSubmitting} value={value} onChange={onChange}>
              <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
              <div className="space-y-4">
                <RadioGroup.Option
                  key={"true"}
                  value={"Yes"}
                  disabled={giftSubscription}
                  className={({ active, disabled }) => `${active ? 'ring-1 ring-offset-2 ring-indigo-500' : ''} relative block rounded-lg border border-gray-300 bg-white shadow-sm px-3 py-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} hover:border-gray-400 sm:flex sm:justify-between focus:outline-none`}
                >
                  {({ checked }) => (
                    <React.Fragment>

                      <div className="flex items-center">
                        <div className="text-sm ">
                          <RadioGroup.Label as="p" className="w-full  font-medium text-gray-900">My billing address is same as my delivery address above</RadioGroup.Label>
                          <RadioGroup.Description as="div" className="text-gray-500"></RadioGroup.Description>
                        </div>
                      </div>
                      <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-left">
                        {checked ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> : null}
                      </RadioGroup.Description>
                      <div
                        className={classNames(
                          checked ? 'border-indigo-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg border-2 pointer-events-none'
                        )}
                        aria-hidden="true" />
                    </React.Fragment>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  key={"false"}
                  value={"No"}
                  className={({ active }) => classNames(
                    active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                    'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-3 py-2 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
                  )}
                >
                  {({ checked }) => (
                    <React.Fragment>
                      <div className="flex items-center">
                        <div className="text-sm ">
                          <RadioGroup.Label as="p" className="w-full  font-medium text-gray-900">I need to specify a different billing address</RadioGroup.Label>
                          <RadioGroup.Description as="div" className="text-gray-500"></RadioGroup.Description>
                        </div>
                      </div>
                      <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-left">
                        {checked ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> : null}
                      </RadioGroup.Description>
                      <div
                        className={classNames(
                          checked ? 'border-indigo-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg border-2 pointer-events-none'
                        )}
                        aria-hidden="true" />
                    </React.Fragment>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          );
        }} />
    </fieldset>

  );
};
