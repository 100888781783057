import React, { InputHTMLAttributes, useState, useContext, useEffect  } from "react";
import { useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import gql from "graphql-tag";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AcceptTermsCheckbox } from "./AcceptTermsCheckbox";
import { OrderConfirmation } from "./OrderConfirmation";
import { IncompleteOrder } from "./IncompleteOrder";
import { PaymentCardEntry } from "./PaymentCardEntry";
import { SubscriptionOfferSelect } from "./SubscriptionOfferSelect";
import { useAppConfigValue } from "../App";
import { BillingAddress } from "./BillingAddress";
import { DeliveryInformation } from "./DeliveryInformation";
import { BillingAddressSameAsDelivery } from "./BillingAddressSameAsDelivery";
import { GiftSubscription } from "./GiftSubscription";
import { SubscriptionFormSubmitButton } from "./SubscriptionFormSubmitButton";
import { CheckoutType } from './CheckoutType';
import { EmailAddress } from "./EmailAddress";
import { AutoRenewal } from "./AutoRenewal";
import { SubscribeToMailingList } from "./SubscribeToMailingList";
export const SUBSCRIBE = gql`
  mutation subscribe($input: SubscriptionInput) {
    createSubscription(input: $input) {
      id
      subscriptionNumber
      entryEndDate
      entryStartDate
      accountID
      redirectURL
    }
  }
`;
export const SubscriptionFormContext: any = React.createContext(null);
export const SubscriptionForm = ({embed}) => {
  const navigate = useNavigate();
  const [subscriptionResult, setSubscriptionResult] = useState<any>(null);
  const [ offer, setOffer ] = useState<any>(null);
  const [ allOffersAllowOptOutOfRenewal, setAllOffersAllowOptOutOfRenewal ] = useState<any>(null);
  const [ allOffersHaveMailingLists, setAllOffersHaveMailingLists ] = useState<any>(null);
  console.log("offerrrrrrr", offer)
  useEffect(() => {
    setValue("addToMailingList", offer ? (offer?.mailingList ? true : false) : true);
    setValue("autoRenewal", offer ? (offer?.autoreschedule ? true : false) : true);
    //trigger();
  }, [offer])
  
  const { publicationName, authNetApiLoginId, authNetClientKey  }: any = useAppConfigValue();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [subscribe, { data, loading, error }] = useMutation(SUBSCRIBE);
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      agreeToTerms: "",
      addToMailingList: true,
      billingAddress: "",
      billingAddress2: "",
      billingAddressSameAsDelivery: "Yes",
      billingCity: "",
      billingCompany: "",
      billingFirstName: "",
      billingLastName: "",
      billingPhone: "",
      billingState: "",
      billingSuite: "",
      billingZip: "",
      cardExpMonth: "",
      cardExpYear: "",
      cardNumber: "",
      cardSecurityCode: "",
      cardType: "",
      cardAddress: "",
      cardAddress2: "",
      cardAddressSameAsBilling: "Yes",
      cardCity: "",
      cardCompany: "",
      cardState: "",
      cardZip: "",
      checkoutType: "",
      maskedCardNumber: "",
      offerId: "",
      paymentNonce: "",
      recaptcha: "",
      recipientAddress: "",
      recipientCity: "",
      recipientCompany: "",
      recipientFirstName: "",
      recipientId: "",
      recipientLastName: "",
      recipientPhone: "",
      recipientState: "",
      recipientSuite: "",
      recipientZip: "",
      registerEmail: "",
      subscriberId: "",
      autoRenewal: true,
      giftSubscription: false,
    },
  });
  const pubName = publicationName;
  const { register, handleSubmit, setError,  getValues, formState: { errors, dirtyFields }, setValue, trigger } = methods;
  console.log("errors", errors);
  console.log("getvalues", getValues());
  
  const cardFieldsDirtied = dirtyFields && dirtyFields.cardExpMonth && dirtyFields.cardExpYear && dirtyFields.cardNumber && dirtyFields.cardSecurityCode && dirtyFields.cardZip && !errors.cardZip && !errors.cardSecurityCode && !errors.cardNumber && !errors.cardExpYear && !errors.cardExpMonth;
  const onSubscriptionFormSubmit = async data => {
    if (executeRecaptcha === undefined) {
      setError("recaptcha", {
        "message": "Unable to verify that you are not a robot. Please disable any browser plugins that may be blocking Google Recaptcha.",
        "type": "manual"
      });
      return null;
    }
    try {
      //Get recaptcha if successful
      const recaptcha = await executeRecaptcha();
      setValue("recaptcha", recaptcha);
      if(!offer?.noPaymentInfoRequired){
        const { cardNumber, cardExpMonth, cardExpYear, cardSecurityCode } = getValues();
        
        const shakeCardForm = await trigger(["cardExpMonth", "cardExpYear", "cardNumber", "cardSecurityCode"]);
        if (!shakeCardForm) return false; //I think this means one of the fields failed validation ... but not sure
        const response: any = await getNonceForCard({
          cardNumber,
          cardExpMonth,
          cardExpYear,
          cardSecurityCode,
          apiLoginID: authNetApiLoginId,
          clientKey: authNetClientKey
        });
        const { opaqueData }: IAcceptJsResponse = response;
        const cardNonce = opaqueData.dataValue;
        const maskedCardNumber = `XXXX XXXX XXXX ${cardNumber.slice(-4)}`;
        const cardType = getCardTypeForNumber(cardNumber);
        setValue("cardNumber", maskedCardNumber);
        setValue("paymentNonce", cardNonce);
        setValue("cardType", cardType);
        trigger();
      }
      const result = await subscribe({ variables: { input: getValues() }  });
      setSubscriptionResult(result.data.createSubscription);
    } catch (e) {
      console.log("error!!!!", e);
      setError("agreeToTerms", { "message": "" + e, "type": "manual" })
      return false;
    }
  }
  if (data && data.createSubscription === null && error) return <IncompleteOrder error={error} />;
  if (subscriptionResult) {
    if (subscriptionResult.access_token) localStorage.setItem("access_token", subscriptionResult.access_token);
    const { subscriptionNumber, entryStartDate, entryEndDate, accountID, redirectURL } = subscriptionResult;
    return (<OrderConfirmation subscriptionNumber={subscriptionNumber} entryStartDate={entryStartDate} entryEndDate={entryEndDate} accountID={accountID} redirectURL={redirectURL} />);
  };
  return (
    <SubscriptionFormContext.Provider value={{ offer, setOffer, allOffersAllowOptOutOfRenewal, allOffersHaveMailingLists, setAllOffersAllowOptOutOfRenewal, setAllOffersHaveMailingLists }}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubscriptionFormSubmit)}>
        <main className={`min-h-full ${!embed ? 'container mx-auto':''}`}>
          <div className={`my-8 ${!embed ? "max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3" : ""}`}>
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white px-4 py-5 sm:px-6 shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900"></h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                  </div>
                  <input type="hidden" {...register("paymentNonce")}></input>
                  <input type="hidden" {...register("cardType")}></input>
                  <input type="hidden" {...register("offerId", { required: true })}></input>
                  <input type="hidden" {...register("recaptcha")}></input>
                  {!embed ? <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 mb-8 text-gray-600 sm:text-3xl sm:truncate">Subscribe to {pubName}</h2>
                  </div> : null }
                  <SubscriptionOfferSelect />
                  <DeliveryInformation />
                  {offer?.noPaymentInfoRequired ? null :<div>
                    <GiftSubscription />
                  <BillingAddressSameAsDelivery />
                  <BillingAddress />
                    </div>
                  }

                </div>
              </section>
            </div>
            <div className="lg:col-start-3 lg:col-span-1">
              <section aria-labelledby="timeline-title" className="mb-3 ">

                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                  <h3 className="text-lg font-medium text-gray-900">Account Details</h3>
                  <fieldset className="mt-2 bg-white">

                    <legend className="block text-sm font-medium text-gray-700">Email Address</legend>
                    <EmailAddress hideLabel={true} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-md bg-transparent focus:z-10 sm:text-sm border-gray-300" />
                    <CheckoutType />
                  </fieldset>
                </div>
              </section>
              {offer?.noPaymentInfoRequired ? null : <section aria-labelledby="timeline-title" className="mb-3 ">
                <div className="bg-white px-4 py-4 shadow sm:rounded-lg sm:px-6">
                  <h3 className="text-lg mb-2 font-medium text-gray-900">
                    Payment Details
                  </h3>
                  <PaymentCardEntry />
                </div>
              </section>}
              { (allOffersAllowOptOutOfRenewal || (offer?.autoreschedule == 1 && offer?.allowOptOutOfAutoRenewal == 1 )) ? <section aria-labelledby="timeline-title" className="mb-3 ">
                <div className="bg-white px-4 py-4 shadow sm:rounded-lg sm:px-6">
                  <h3 className="text-lg mb-2 font-medium text-gray-900">
                    Renewal
                  </h3>
                  <AutoRenewal />
                </div>
              </section>: null}
              <section aria-labelledby="timeline-title">
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                  <AcceptTermsCheckbox />
                  {(allOffersHaveMailingLists || (offer?.mailingList)) ?  <SubscribeToMailingList /> : null}
                  <SubscriptionFormSubmitButton />
                </div>
                {error ? <IncompleteOrder error={error} /> : null}
              </section>
            </div>
          </div>
        </main>
      </form>
    </FormProvider>
    </SubscriptionFormContext.Provider>
  );
};

declare var Accept: any;
export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  type?: string;
  validate?: object;
  required?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
}

export interface IAcceptJsMessage {
  code: string;
  text: string;
}
export interface IAcceptJsOpaqueData {
  dataDescriptor: string;
  dataValue: string;
}
export interface IAcceptJsResponse {
  messages: [IAcceptJsMessage];
  opaqueData: IAcceptJsOpaqueData;
}
export interface NonceForCard {
  paymentNonce: string;
  cardType?: string;
  cardExpMonth?: string;
  maskedCardNumber?: string;
  cardExpYear?: string;
};
export interface IGetNonceForCard {
  cardNumber: string;
  cardExpMonth: string;
  cardExpYear: string;
  cardSecurityCode: string;
}
export type SubscriptionFormParams = {
  defaultOfferId: string;
};

export const SUBSCRIPTION_OFFERS = gql`
  query getSubscriptionOffers($uuid: String) {
    getSubscriptionOffers(uuid: $uuid) {
      id
      uuid
      offerName
      displayPrice
      discountFinePrint
      mailingList
      features
      imageUrl
      price
      allowOptOutOfAutoRenewal
      noPaymentInfoRequired
      autoreschedule
    }
  }
`;

//Functions
export const getCardTypeForNumber = number => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";
  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)
  )
    return "Mastercard";
  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "AMEX";
  // Discover
  re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
  if (number.match(re) != null) return "Discover";
  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "Diners";
  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return "Diners - Carte Blanche";
  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "JCB";
  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";
  return "";
};

export const minPass = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
export const getRangeOfYears = (count, start = new Date().getFullYear() || 2018) => { let years = new Array(); for (let i = start; i <= start + count; i++) { years.push(i) }; return years; };
export const acceptJsLibraryUri = process.env.NODE_ENV === "development" ? "https://jstest.authorize.net/v1/Accept.js" : "https://js.authorize.net/v1/Accept.js";
export function classNames(...classes) { return classes.filter(Boolean).join(' ') }
export const addAcceptJsScript = () => {
  const script = document.createElement("script");
  script.setAttribute("charset", "utf-8");
  script.src = acceptJsLibraryUri;
  document.head.appendChild(script);
};
export const removeAcceptJsScript = () => {
  const scripts = document.head.getElementsByTagName("script");
  Array.from(scripts)
    .filter(script => script.src === acceptJsLibraryUri)
    .forEach(injectedScript => document.head.removeChild(injectedScript));
};
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getNonceForCard = async ({ cardNumber, cardExpMonth, cardExpYear, cardSecurityCode, apiLoginID, clientKey }) => {
  const payload = {
    authData: {
      apiLoginID,
      clientKey
    },
    cardData: {
      cardNumber: cardNumber.replace(/\D/g, ""),
      year: cardExpYear,
      month: cardExpMonth,
      cardCode: cardSecurityCode,
    },
  };
  try {
    return new Promise((resolve, reject) =>
      Accept.dispatchData(payload, res => {
        return res.messages.resultCode === "Error" ? reject(res) : resolve(res);
      }),
    );
  } catch (error) {
    console.error("Authorize.net Accept.js Error:", error);
    return false;
  }
}

export default SubscriptionForm;