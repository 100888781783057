import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { InputFieldProps } from "./SubscriptionForm";


export const InputField: FC<InputFieldProps> = ({ label, hideLabel = false, name, type = "text", placeholder, validate, required = false, ...rest }) => {
  const { register, formState: { errors, isSubmitting } } = useFormContext();
  let registerOptions: any = { required };
  if (validate)
    registerOptions.validate = validate;
  return (
    <div className="w-full">
      <label htmlFor="email" className={hideLabel ? "sr-only" : "block text-sm font-medium text-gray-700"}>{label}</label>
      <div className="">
        <input
          type={type}
          disabled={isSubmitting} 
          className="rounded-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
          placeholder={hideLabel ? label : placeholder}
          {...register(name, registerOptions)}
          {...rest} />
      </div>
      {errors[name] && <p className="text-red-500 text-xs italic">{errors[name].message}</p>}
    </div>
  );
};
