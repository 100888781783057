import { useContext } from "react";

import { useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const LOGIN = gql`
  mutation subscriberLogin($input: SubscriberLoginInput) {
    subscriberLogin(input: $input){
      access_token
      contact{
        accountId
        address
        bdDay
        bdMonth
        city
        company
        contactFirst
        contactId
        contactLast
        contactUid
        country
        cphoneAll
        creator
        donotcall
        donotemail
        email
        esrecorduuid
        extension
        faxDedicated
        greeting
        hideContact
        mailingList
        mergeletter
        modifier
        nickname
        notes
        phone1
        phone2
        phone2type
        phone3
        Phone3Type
        salutation
        searchrequesttemp
        sort
        state
        subsonly
        suite
        timestampCreated
        timestampModified
        title
        tsModifiedClient
        usecontactaddress
        webemailconfirmed
        weblastlogin
        webpasswordhash
        webpasswordresetdate
        webpasswordresettime
        webpasswordresettoken
        webpreferences
        zip
      }
    }
  }
`;

const Login = () => {
  const context: any = useContext(AppContext);
  const methods = useForm();
  const { register, handleSubmit, setError, formState: { errors, isSubmitting } } = methods;
  const { setUser, appVersion, privacyPolicyUrl, logoUrl, headerBackgroundColor } = context;
  const [login] = useMutation(LOGIN);
    const { clientId } = context;
  let navigate = useNavigate();
  const handleLogin = async (data) => {
    const { email, password } = data;
    await localStorage.clear();
    try {
      const res = await login({
        variables: {input: { email, password, client_id: clientId}},
      });
      const { data } = res;
      if (data && data.subscriberLogin) {
        //await setUser(data.subscriberLogin.contact);
        await localStorage.setItem("contact", JSON.stringify(data.subscriberLogin.contact));
        await localStorage.setItem("access_token", data.subscriberLogin.access_token);
        navigate("/account", { replace: true });
      }
    } catch (e: any) {
      if (e?.graphQLErrors[0]?.message === "Invalid email and password combination") {
        setError('password', { type: 'custom', message: 'Invalid username and/or password' });
      }
    }
  }
  return (
    <div style={{backgroundColor: headerBackgroundColor}} className="h-screen flex flex-col justify-center pt-12  flex-grow">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logoUrl}
          alt="Logo"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex-grow ">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(handleLogin)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  {...register("email", { required: true })}
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  {...register("password", { required: true })}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.password && (<div className="flex w-full justify-center"><p className="text-sm text-red-500">Error: {errors.password.type === "required" ? "Password is required" : errors.password.message}</p></div>)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">

              </div>
              <div className="text-sm">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="mt-32 w-full flex-shrink-0 static max-w-container mx-auto border-t py-2 text-center text-sm text-gray-500 sm:flex sm:items-center sm:justify-center bg-white z-10">
      <p className="mt-2 sm:mt-0 sm:mr-3 sm:border-r sm:border-gray-200 sm:pr-3">
          <a className="text-xs hover:text-teal-600" href={privacyPolicyUrl}>Privacy Policy</a>
        </p>
        <p>© 2023 Pre1 Software Inc. All rights reserved.</p>
        <p className="mt-2 sm:mt-0 sm:ml-3 sm:border-l sm:border-gray-200 sm:pl-3 right-1 text-xs">Subscriberportal {appVersion}</p>
      </div>
    </div>

  );
};

export default Login;