import { useFormContext, Controller } from "react-hook-form";
import { Switch } from '@headlessui/react';

export const AutoRenewal = () => {
  const { control, watch, formState: { isSubmitting} } = useFormContext();
  const currentValue = watch("autoRenewal");
  return (
    <div className="mt-6">
    <div className="flex row">
      <fieldset disabled={isSubmitting} className="">
        <Controller
          rules={{ required: false }}
          control={control}
          name="autoRenewal"
          render={({ field }) => {
            const { name, onBlur, onChange, value } = field;
            return (
                <Switch checked={value} onChange={onChange} className={`${value ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 mr-2  items-center rounded-full`}>
                <span className="sr-only">Renew Automatically</span>
                <span className={`${value ? 'translate-x-6' : 'translate-x-1' } inline-block h-4 w-4 transform rounded-full bg-white transition`}></span>
              </Switch>
            );
          }} />
      </fieldset>
      <div>Renew Automatically</div>
      <div className={`${currentValue ? "text-green-500" : "text-red-500"}  font-bold ml-4`}>{currentValue ? "On": "Off"}</div>
    </div>
    <div className="text-xs text-gray-500">Choose the option above to make sure your subscription renews so you don’t miss an issue!</div>
    </div>

  );
};
