import { useFormContext } from "react-hook-form";
import { useAppConfigValue } from "../App";

export const AcceptTermsCheckbox = () => {
  const { privacyPolicyUrl, termsAndConditionsUrl }: any = useAppConfigValue()
  const { register, trigger, formState: { isSubmitting, errors} } = useFormContext();
  return (
    <fieldset disabled={isSubmitting}  className="space-y-5">
      <legend className="sr-only">Agree to Terms and Conditions</legend>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            aria-describedby="terms"
            title="I agree to the terms and conditions"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            {...register("agreeToTerms", { required: true, onChange: () => trigger("agreeToTerms") })} />
        </div>
        <div className="ml-3">
          <label htmlFor="terms" className=" text-sm font-medium">
            <span>I agree to the </span>
            <a href={termsAndConditionsUrl} target="_blank">terms and conditions</a>
          </label>
          <p id="comments-description" className="text-xs text-gray-500">
            <span>To learn more about how your data will be used please refer to our </span>
            <a className="font-bold underline" href={privacyPolicyUrl} target="_blank">privacy policy</a>
          </p>
        </div>
      </div>
      {errors.agreeToTerms && <p className="text-red-500 text-xs italic">{errors.agreeToTerms.message }</p>}
    </fieldset>);
};
