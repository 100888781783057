import React from "react";
import { ExclamationIcon } from '@heroicons/react/solid'
import { useFormContext } from "react-hook-form";

export const IncompleteOrder = ({ error }) => {
  const { setError } = useFormContext();
  return (
    <React.Fragment>
      <div className="mt-4 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">We encountered a problem processing your subscription</p>
            {error.graphQLErrors ? error.graphQLErrors.map(({ message }, i) => {
              switch (message) {
                case "OfferId not specified": setError("offerId", { "message": "Please select an offer", type: "manual" }); return null;
                case "payment nonce not specified": setError("cardNumber", { message: "Error with credit card validation", type: "manual" }); return null;
                //These are the rest of the possible errors - override them by adding more cases - above examples are errors shown next to their imput, the default error...
                //...will print below the subscription button for errors related to a specific field should always use setError so the user can see where the mistake was made
                //Recaptcha not specified
                //Missing or incomplete delivery address
                //Missing email for new account
                //Missing or incomplete billing address
                //Starts with...Error getting values for subscription offer ID 
                //Subscription offer is misconfigured and cannot be used
                //Email is already in use
                //Starts with... Error creating account: 
                //Error during account creation
                //Starts with ... Error getting payment processing details for pub
                //Unsupported Payment Gateway. Payment not processed.
                //Starts with ... Error creating payment profile for contact ID 
                //Unable to save payment information
                //Starts with ... Error creating customer profile record for contact ID
                //Starts with ... Error creating recipient contact:
                //Starts with ... Error creating subscription quote:
                //Starts with ... Error validating subscription
                //Starts with ... Cannot schedule subscription quote
                //Starts with ... Error processing payment: 
                //Payment did not return authorization code
                //Cannot get GL code for insert.
                //Starts with ... rror applying payment
                //Starts with ... Error applying payment:
                //Starts with ... Error getting rep for task
                default: return (<p className="font-medium underline text-yellow-700 hover:text-yellow-600">{error.networkError.message}</p>);
              }
            }) : null}
            {error.networkError ? (<p className="font-medium underline text-yellow-700 hover:text-yellow-600">{error.networkError.message}</p>) : null}
            <p>Please contact customer service and provide the above error so they can address any issues and complete your order.</p>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};
