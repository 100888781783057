import { useFormContext } from "react-hook-form";
import { InputField } from "./InputField";

export const BillingAddress = () => {
  const { watch, register, formState: { isSubmitting, errors } } = useFormContext();
  return watch("billingAddressSameAsDelivery") === "Yes" ? null : (
    <fieldset disabled={isSubmitting} className="mt-6 bg-white">
      <legend className="block text-sm font-medium text-gray-700">Billing address</legend>
      <div className="mt-1 mb-4 bg-white rounded-md shadow-sm -space-y-px">
        <InputField hideLabel={true} label={"Business Name (Optional)"} autoComplete={"section-billing billing org organization"} name={"billingCompany"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300" />
        <div className="flex -space-x-px">
          <InputField hideLabel={true} label={"First Name"} required={true} autoComplete={"section-billing billing given-name"} name={"billingFirstName"} />
          <InputField hideLabel={true} label={"Last Name"} autoComplete={"section-billing billing family-name"} name={"billingLastName"} />
        </div>
        {errors.billingFirstName || errors.billingLastName ? <p className="text-red-500 text-xs italic">Missing First or Last Name</p> : null}
        <InputField hideLabel={true} label={"Phone (Optional)"} autoComplete={"section-billing billing tel"} name={"billingPhone"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300" />
      </div>
      <div>
        <InputField required={true} hideLabel={true} label={"Address"} autoComplete={"section-billing billing address-line1"} name={"billingAddress"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full bg-transparent rounded-none rounded-t-md focus:z-10 sm:text-sm border-gray-300" />
        <InputField hideLabel={true} label={"Apt/Suite/etc (Optional)"} autoComplete={"section-billing billing address-line2"} name={"billingSuite"} />
        <div className="flex -space-x-px">
          <InputField required={true} hideLabel={true} label={"City"} autoComplete={"section-billing billing address-level2"} name={"billingCity"} />
          <InputField required={true} hideLabel={true} label={"State"} autoComplete={"section-billing billing address-level1"} name={"billingState"} />
          <InputField required={true} hideLabel={true} label={"Zip"} autoComplete={"section-billing billing postal-code"} name={"billingZip"} />
        </div>
        <div>
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            id="country"
            {...register("country")}
            autoComplete="country-name"
            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
          >
            <option>United States</option>
            <option>Canada</option>
            <option>Mexico</option>
          </select>
          {errors.country && <p className="text-red-500 text-xs italic">{errors.country.message}</p>}
        </div>
        {errors.billingAddress || errors.billingCity || errors.billingState || errors.billingZip ? <p className="text-red-500 text-xs italic">Address Appears Incomplete</p> : null}
      </div>
    </fieldset>
  );
};
