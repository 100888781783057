import { useFormContext } from "react-hook-form";

import { InputField } from "./InputField";

export const DeliveryInformation = () => {
  const { register, formState: { isSubmitting, errors } } = useFormContext();
  return (
    <fieldset disabled={isSubmitting} className="mt-6 bg-white">
      <legend className="block text-sm font-medium text-gray-700">Recipient Contact </legend>
      <div className="mt-1 mb-4 bg-white rounded-none rounded-t-md shadow-sm -space-y-px">
        <div className="flex -space-x-px">
          <InputField hideLabel={true} label={"First Name"} autoComplete={"section-recipient shipping given-name"} name={"recipientFirstName"} required={true} />
          <InputField hideLabel={true} label={"Last Name"} autoComplete={"section-recipient shipping family-name"} name={"recipientLastName"} required={true} />
        </div>
        {errors.recipientFirstName || errors.recipientLastName ? <p className="text-red-500 text-xs italic">Missing First or Last Name</p> : null}
        <InputField hideLabel={true} label={"Phone Number (Optional)"} autoComplete={"section-recipient shipping tel"} name={"recipientPhone"} />
        <InputField hideLabel={true} label={"Company Name  (Optional)"} autoComplete={"section-recipient shipping organization"} name={"recipientCompany"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300" />
      </div>
      <legend className="block text-sm font-medium text-gray-700">Delivery Address</legend>
      <div>
        <InputField hideLabel={true} label={"Address"} required={true} autoComplete={"section-recipient shipping address-line1"} name={"recipientAddress"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full bg-transparent rounded-none rounded-t-md focus:z-10 sm:text-sm border-gray-300" />
        <InputField hideLabel={true} label={"Apt/Suite/etc"} autoComplete={"section-recipient shipping address-line2"} name={"recipientSuite"} />
        <div className="flex -space-x-px">
          <InputField hideLabel={true} label={"City"} required={true} autoComplete={"section-recipient shipping address-level2"} name={"recipientCity"} />
          <InputField hideLabel={true} label={"State"} required={true} autoComplete={"section-recipient shipping address-level1"} name={"recipientState"} />
          <InputField hideLabel={true} label={"Zip"} required={true} autoComplete={"section-recipient shipping postal-code"} name={"recipientZip"} />
        </div>

        <div>
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            id="country"
            {...register("country")}
            autoComplete="country-name"
            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
          >
            <option>United States</option>
            <option>Canada</option>
            <option>Mexico</option>
          </select>
        </div>
        {errors.recipientAddress || errors.recipientCity || errors.recipientState || errors.recipientZip ? <p className="text-red-500 text-xs italic">Address Appears Incomplete</p> : null}
      </div>
    </fieldset>
  );
};
