import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import { useFormContext, useFormState } from "react-hook-form";
import { SubscriptionFormParams, SUBSCRIPTION_OFFERS, formatter, SubscriptionFormContext } from "./SubscriptionForm";
import { XCircleIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'

import {  } from './SubscriptionForm';

export const SubscriptionOfferSelect = () => {
  const { defaultOfferId } = useParams<SubscriptionFormParams>();
  console.log("defaultOfferId", defaultOfferId);
  const [availableOfferCount, setAvailableOfferCount] = useState(0);

  const  {setOffer, setAllOffersAllowOptOutOfRenewal, setAllOffersHaveMailingLists}:any = useContext(SubscriptionFormContext);
  const { setValue, watch, formState: { errors, isSubmitting }, trigger } = useFormContext();



  const queryObj = useQuery(SUBSCRIPTION_OFFERS, { variables: { uuid: watch("offerId") } });
  const { data, error, loading } = queryObj;
  const handleRemoveOfferSelection = () => {
    setValue("offerId", "");
    trigger("offerId");
  }
  useEffect(() => {
    setValue("offerId", defaultOfferId);
  }, []);
  watch("offerId");
  useEffect(() => {
    setAvailableOfferCount(data?.getSubscriptionOffers?.length > availableOfferCount ? data.getSubscriptionOffers.length : availableOfferCount)
  }, [data]);
  if (error)
    return <div>Error loading subscription offers. Please try again later or contact our office for assistance.</div>;
  if (loading)
    return (<div className="h-64"></div>);
  const { getSubscriptionOffers } = data;
  const offersAllowingOptOutOfRenew = data?.getSubscriptionOffers.filter(Ioffer => Ioffer.autoreschedule === 1 && Ioffer.allowOptOutOfAutoRenewal === 1);
  const allOffersAllowOptOutOfRenew = offersAllowingOptOutOfRenew.length == data?.getSubscriptionOffers.length;
  const offersThatHaveMailingLists = data?.getSubscriptionOffers.filter(({mailingList}) => mailingList !== null && mailingList !== undefined );
  const allOffersHaveMailingLists = offersThatHaveMailingLists.length == data?.getSubscriptionOffers.length;
  setAllOffersAllowOptOutOfRenewal(allOffersAllowOptOutOfRenew);
  setAllOffersHaveMailingLists(allOffersHaveMailingLists);
  const selectedOffer = data?.getSubscriptionOffers ? getSubscriptionOffers.reduce((prev, curr) => (watch("offerId") === curr.uuid ? curr : prev), null) : false;
  console.log('offer', selectedOffer);
  setOffer(selectedOffer)
  if (availableOfferCount === 0)
    return <div>No current subscription offers.</div>;
  //if (availableOfferCount === 1 && !watch("offerId"))
  //setValue("offerId", data.getSubscriptionOffers[0].uuid);

  return (
    <RadioGroup value={watch("offerId")} onChange={(val) => {
      setValue("offerId", val);
    }}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">Select a subscription option</RadioGroup.Label>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {getSubscriptionOffers.map(({ offerName, features, displayPrice, discountFinePrint, uuid, promoImageUrl }, index) => {
          const idx = index;
          return (
            <RadioGroup.Option key={index} value={uuid} className={({ checked, active }) => `${checked ? 'border-transparent' : 'border-gray-300'} ${active ? 'border-indigo-500 ring-2 ring-indigo-500' : ''} relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none`}>
              {({ checked, active }) => (
                <>
                  <span className="flex-1 flex">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                        {offerName}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                        {discountFinePrint}
                      </RadioGroup.Description>
                      <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                        {displayPrice}
                        <div>{features}</div>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon className={`${!checked ? 'invisible' : ''} h-5 w-5 text-indigo-600`} aria-hidden="true" />
                  <span className={`${active ? 'border' : 'border-2'} ${checked ? 'border-indigo-500' : 'border-transparent'} absolute -inset-px rounded-lg pointer-events-none`} aria-hidden="true" />
                </>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
      {watch("offerId") && availableOfferCount > 1 ? (
                <button disabled={isSubmitting} onClick={handleRemoveOfferSelection} type="button" className=" items-center w-full mt-2 mx-auto px-1 py-1 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                  Change selected offer
                </button>
              ) : null}
    </RadioGroup>
  );
  /*  <React.Fragment>
     <div className="md:grid md:gap-4 md:grid-cols-3">
       {getSubscriptionOffers.map(({ offerName, features, displayPrice, discountFinePrint, uuid, promoImageUrl }, index) => {
         const idx = index;
         return (
             <div onClick={() => setValue("offerId", uuid)} key={idx} className="mt-6 pt-2  col-span-3 order-4 cursor-pointer text-center w-full bg-indigo-50 rounded-lg shadow divide-y divide-gray-200">
               {offerName} - {displayPrice}  {discountFinePrint ? discountFinePrint.split("\\n/").map((line, discountLineIdx) => (<span key={discountLineIdx} className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">{line}</span>)) : null}
               <div className="-m-px flex divide-x divide-gray-200 mt-2">
                 <div className="w-0 flex-1 flex bg-indigo-300">
                   <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-1 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                     <span className="ml-3">Choose this offer</span>
                   </div>
                 </div>
               </div>
             </div>
           );
       })}
     </div>
     {errors.offerId ? (<div className="mt-4 rounded-md bg-red-50 p-4">
       <div className="flex">
         <div className="flex-shrink-0">
           <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
         </div>
         <div className="ml-3">
           <h3 className="text-sm font-medium text-red-800">You must choose one of the above offers in order to subscribe</h3>
         </div>
       </div>
     </div>) : null}
   </React.Fragment>
   selectedOffer ? (
    <React.Fragment>
      <div className="bg-white shadow-lg border sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Selected Offer</h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>{selectedOffer.offerName}: {selectedOffer.displayPrice}</p>
            </div>
            <div className="mt-2 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              {availableOfferCount > 1 ? (
                <button disabled={isSubmitting} onClick={handleRemoveOfferSelection} type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                  Change selected offer
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
   ); */
};
