import { useContext, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useMutation, gql, useQuery } from "@apollo/client";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NewspaperIcon, PaperClipIcon } from "@heroicons/react/solid";
import { Dialog } from '@headlessui/react'
import { BriefcaseIcon, XIcon } from '@heroicons/react/solid';

export const ACCOUNT = gql`
  query getSubscriberAccount {
    getSubscriberAccount{
      accountID
      acctCategory
      acctDiscountPercent
      acctDiscountType
      acctType
      address
      address2
      advertiserPortalAgreement
      agencyID
      alert
      annualAdBudget
      annualRevenue
      billCity
      billCompany
      billCountry
      billCustomerLoginPassword
      billEmail
      billFirst
      billingCycle
      billLast
      billPhone
      billState
      billStreet
      billSuite
      billToID
      billZip
      city
      classifiedRep1
      classifiedRep2
      commissionExpireDate
      commRateSale
      company
      contactQuality
      country
      creator
      creditHold
      creditLimit
      creditStatus
      crmshowhiddencontacts
      crmshowsubscontacts
      dateCreated
      dateEstablished
      dateModified
      discountLog
      displayFUCount
      displayRepID
      doNotCall
      edition
      financeChargesYN
      firstFUDate
      firstPaymentDate
      firstRunDate
      invoiceByAd
      invoiceFreeClass
      invoiceFreeDisp
      invoicePrepaidClass
      invoicePrepaidDisp
      invoices_Email
      invoices_PrInt
      invoiceType
      lastClass1FU
      lastClass2FU
      lastCollectionFU
      lastDisplayFU
      lastPaymentAmount
      lastPaymentDate
      lastRunDate
      latitude
      longitude
      mailingCode
      mailingList
      mergeLetter
      modifier
      Notes
      oldAcctID
      primaryPublicationId
      primPubID
      recurringChargeActive
      recurringChargeAmt
      recurringChargeCardorcheck
      recurringChargeChoice
      refreshTs
      rep2type
      requirePo
      sacctCredit
      sdueunpublishedIa
      sicCode
      sinvoiceDue
      state
      statementsemail
      statementsprint
      status
      subscriptionsonly
      sunearnedincome
      taxableYnAdvertising
      taxableYnSubscriptions
      taxrateadvertising
      taxratesubscriptions
      tearsheetsprintcbcount
      tearsheetsprintcwcount
      tearsheetsprintdisplaycount
      terms
      termsReqChange
      termsReqDate
      timeCreated
      timeModified
      tsModifiedClient
      webAddress
      zip
      balance
      creditBalance
      invoiceBalance
      subscriptions{
        accountID
        activeInactive
        alert
        autoInvoice
        autoReschedule
        billingInsertionDates
        billPeriodDate
        description
        discAmt
        discPercent
        discType
        entryEndDate
        entryStartDate
        expireDate
        extChargeOverride
        firstRunDate
        issueID
        issueSerialID
        logoPub
        mailingCode
        modifier
        notes
        PO
        pubID
        publishedInsertions
        charge
        publishedTotal
        contactIdBuyer
        rateID
        contactIdRecipient
        remainingInsertions
        copies
        remainingTotal
        disc
        renewalDate
        renewedFromSubsID
        runIssuesEntry
        runIssueSerialIDs
        schedulePeriodCount
        sellerID
        soldInsertions
        soldTotal
        status
        subscriptionNumber
        takerID
        timestampCreated
        timestampModified
        totalInsertions
        tsModClient
        zone
        subscriptionId
      }
    }
  }
`;

const Account = () => {
  const context: any = useContext(AppContext);
  const methods = useForm();
  const [user, setUser]: any = useState(null);
  useMemo(async () => {
    const contact = await localStorage.getItem("contact");
    if (contact) {
      setUser(JSON.parse(contact));
    }
  }, []);

  const { data, loading, error } = useQuery(ACCOUNT);
  console.log("data", data)
  const { register, handleSubmit, setError, formState: { errors, isSubmitting } } = methods;
  const { appVersion, privacyPolicyUrl, logoUrl, publicationName, headerBackgroundColor, } = context;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navigation = [
    { name: 'Subscriptions', href: '/subscriptions' }
  ]

  const { clientId } = context;
  let navigate = useNavigate();
  return (
    <>
    <header style={{ backgroundColor: headerBackgroundColor }} className="bg-white">
      <nav className="mx-auto flex  items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">{publicationName}</span>
            <img className="h-8 w-auto" src={logoUrl} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <BriefcaseIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="/logout" className="text-sm font-semibold leading-6 text-gray-900">
            Log out <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      {/* <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog> */}
    </header>
    <div className="flex flex-col justify-top  flex-grow">
      <div className="max-w-7xl w-full mx-auto">
        <div className="px-4 py-5 sm:px-6">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">My Subscription Account</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.contactFirst} {user?.contactLast}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.phone1}{user?.extension ? " x" + user?.extension : ""}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.email}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Subscription Account ID</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.accountId}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Subscriptions</dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {data?.getSubscriberAccount.subscriptions?.map((subscription, idx) => (<li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div key={idx} className="flex w-0 flex-1 items-center">
                        <NewspaperIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">{subscription.description}</span>
                          <span className="flex-shrink-0 text-gray-400">{subscription.firstRunDate} {subscription.expireDate}</span>
                        </div>
                      </div>
                      {subscription.activeInactive == "Inactive" ? <div className="ml-4 flex-shrink-0">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Renew
                        </a>
                      </div> : <div className="ml-4 flex-shrink-0">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Details
                        </a>
                      </div>}
                    </li>))}
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Account;