import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';

const standalone = !!document.getElementById('root');
const rootElement =  standalone ? document.getElementById('root') : document.getElementsByClassName('wp-smartpublisher-shortcode')[0];
if (!rootElement) throw new Error('Failed to find the root element');
const rootHTMLElement = rootElement as HTMLElement;
const dataSet = rootHTMLElement.dataset;
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App embed={standalone? false : true} config={dataSet} />
    </ErrorBoundary>
  </React.StrictMode>

);

reportWebVitals();
