import React from "react";
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("Caught in Error Boundary", error, errorInfo);
    newrelic.noticeError(error, errorInfo);
  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">An error occured</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Please try refreshing the page. If you continue to have trouble please contact our office for help with subscribing.</p>
          </div>
        </div>
      </div>)
    }

    return this.props.children;
  }
}

export default ErrorBoundary;