import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { addAcceptJsScript, removeAcceptJsScript } from "./SubscriptionForm";
import { InputField } from "./InputField";
import { CardAddressSameAsBilling } from "./CardAddressSameAsBilling";
export const PaymentCardEntry = () => {
  const { register, watch, formState, getValues, setValue } = useFormContext();
  const { errors } = formState;
  useEffect(() => {
    addAcceptJsScript();
    return removeAcceptJsScript; //Pass unsubscribe function to remove script when unmounted
  }, []); //Run once upon mount
  watch(["cardExpMonth", "cardExpYear", "cardNumber", "cardSecurityCode"]);
  useEffect(() => {
    //If auto-fill enters a 4 digit year change it to 2 digits
    if (getValues().cardExpYear?.length == 4) {
      setValue("cardExpYear", getValues().cardExpYear.slice(2, 4));
    }
  }, [watch("cardExpYear")]);
  return (
    <React.Fragment>
      <fieldset disabled={formState.isSubmitting}>
        <legend className="block text-sm font-medium text-gray-700">Card Details</legend>
        <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="card-number" className="sr-only">
              Card number
            </label>
            <input
              type="text"
              {...register("cardNumber", { minLength: watch("cardNumber")[0] == "3" ? 15 : 16, maxLength: 20, required: true })}
              id="card-number"
              className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Card number" />
            {errors.cardNumber && <p className="text-red-500 text-xs italic">Card number is required</p>}
          </div>
          <div className="flex -space-x-px">
            <div className="w-1/2 flex-1 min-w-0">
              <label htmlFor="card-expiration-date" className="sr-only">
                Expiration date
              </label>
              <input
                type="text"
                title="Card expiration month"
                {...register("cardExpMonth", { minLength: 1, maxLength: 2, min: 1, max: 12, required: true })}
                id="card-expiration-month"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="MM" />
              {errors.cardExpMonth && <p className="text-red-500 text-xs italic">Expiration month is required</p>}
            </div>
            <div className="flex-1 min-w-0">
              <input
                type="text"
                {...register("cardExpYear", { minLength: 2, maxLength: 2, min: 21, max: 99, required: true })}
                id="card-expiration-year"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="YY" />
              {errors.cardExpYear && <p className="text-red-500 text-xs italic">Expiration year is required</p>}
            </div>
            <div className="flex-1 min-w-0">
              <label htmlFor="card-cvc" className="sr-only">
                CVC
              </label>
              <input
                type="text"
                {...register("cardSecurityCode", {
                  minLength: {
                    message: "Card Security Code should be at least 3 numbers",
                    value: 3
                  }, maxLength: {
                    message: "Card Security Code should be at most 5 numbers",
                    value: 5
                  },
                  required: true
                })}
                id="card-cvc"
                autoComplete="cc-csc"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="CVC" />
              {errors.cardSecurityCode && <p className="text-red-500 text-xs italic">Card Security Code is required</p>}
            </div>
          </div>
        </div>
      </fieldset>
      <CardAddressSameAsBilling />
      {watch("cardAddressSameAsBilling") === "No" ? 
      <fieldset disabled={formState.isSubmitting} className="mt-6 bg-white">
        <legend className="block text-sm font-medium text-gray-700">Card Address</legend>
        <div>
          <InputField required={true} hideLabel={true} label={"Address"} autoComplete={"section-billing billing address-line1"} name={"cardAddress"} className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full bg-transparent rounded-none rounded-t-md focus:z-10 sm:text-sm border-gray-300" />
          <InputField hideLabel={true} label={"Apt/Suite/etc (Optional)"} autoComplete={"section-billing billing address-line2"} name={"cardAddress2"} />
          <div className="flex -space-x-px">
            <InputField required={true} hideLabel={true} label={"City"} autoComplete={"section-billing billing address-level2"} name={"cardCity"} />
            <InputField required={true} hideLabel={true} label={"State"} autoComplete={"section-billing billing address-level1"} name={"cardState"} />
            <InputField required={true} hideLabel={true} label={"Zip"} autoComplete={"section-billing billing postal-code"} name={"cardZip"} />
          </div>
          <div>
            <label htmlFor="cardCountry" className="sr-only">
              Country
            </label>
            <select
              id="cardCountry"
              {...register("cardCountry")}
              autoComplete="country-name"
              className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            >
              <option>United States</option>
              <option>Canada</option>
              <option>Mexico</option>
            </select>
            {errors.country && <p className="text-red-500 text-xs italic">{errors.country.message}</p>}
          </div>
         
        </div>
      </fieldset>: null }
    </React.Fragment>
  );
};
/*
 <div className="mt-1 rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="country" className="sr-only">
              Country
            </label>
            <select
              id="country"
              {...register("cardCountry", { required: true })}
              autoComplete="country-name"
              className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            >
              <option>United States</option>
              <option>Canada</option>
              <option>Mexico</option>
            </select>
            {errors.cardCountry && <p className="text-red-500 text-xs italic">Country is required</p>}
          </div>
          <div>
            <label htmlFor="postal-code" className="sr-only">
              ZIP / Postal code
            </label>
            <input
              type="text"
              {...register("cardZip", {
                required: true,
                maxLength: {
                  message: "Maximum 9-digit zip code",
                  value: 10
                }, minLength: {
                  message: "Minimum 5-digit zip code",
                  value: 5
                }
              })}
              id="postal-code"
              autoComplete="postal-code"
              className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="ZIP / Postal code" />
            {errors.cardZip && <p className="text-red-500 text-xs italic">Zip code is required</p>}
          </div>
        </div>
*/