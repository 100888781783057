import {
  Outlet
} from "react-router-dom";
export const Layout = ({embed, config}) => {
  return (
  <div className={`flex flex-col ${!embed ? "h-screen" : ""} justify-between`}>
    <header></header>
    <main className="mb-auto">
      <Outlet />
    </main>
    {!embed ? <footer className="w-full static max-w-container mx-auto border-t py-6 text-center text-sm text-gray-500 sm:flex sm:items-center sm:justify-center bg-white z-10">
      <p><a className=" cursor-pointer " href={config.publicationWebsite}>© 2023 {config.publicationName} All rights reserved.</a></p>
      {config.termsAndConditionsUrl ? <p className="mt-2 sm:mt-0 sm:ml-3 sm:border-l sm:border-gray-200 sm:pl-3">
        <a className="cursor-pointer hover:text-teal-600" href={config.termsAndConditionsUrl}>Terms and Conditions</a>
      </p> : null }
      <p className="mt-2 sm:mt-0 sm:ml-3 sm:border-l sm:border-gray-200 sm:pl-3">
        <a className="cursor-pointer hover:text-teal-600" href={config.privacyPolicyUrl}>Privacy Policy</a>
      </p>
    </footer>: null}
  </div>
);
  };
export default Layout;