import React from "react";
import { useFormContext } from "react-hook-form";
export const SubscriptionFormSubmitButton = ({ ...rest }) => {
  const { formState } = useFormContext();
  const { isValid, isSubmitting, errors } = formState;
  return (
    <React.Fragment>
      <div className="text-center w-full">
        <button type="submit" {...rest}  disabled={isSubmitting || !isValid} title="Start Subscription" className={`disabled:opacity-25 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm`}>
          Start Subscription
        </button>
        {Object.keys(errors).length>0 ? <p className="text-red-500 text-xs italic">Errors found with form, please correct and re-submit form</p> : null}
      </div>
    </React.Fragment>
  );
};
