import React, { Suspense, useContext } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import apolloLogger from 'apollo-link-logger';
import { createUploadLink } from 'apollo-upload-client';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import SubscriptionForm from "./components/SubscriptionForm";
import Layout from "./Layout";
import Login from './components/Login';
import Account from './components/Account';
import { Navigate, } from "react-router-dom";
export const AppContext: any = React.createContext(null);

export const useAppConfigValue = () => useContext(AppContext);

function App({ embed, config }) {
  const Router = embed ? HashRouter : BrowserRouter;
  const { graphqlEndpoint, clientId } = config;
  const url = new URL('/pre1/graphql', window.location.href);
  const uri = process.env.NODE_ENV == "development" ? "http://localhost:4000/graphql" : (graphqlEndpoint ? graphqlEndpoint : url.href);
  const httpLink = createUploadLink({ uri });

  const [user, setUser] = React.useState(null);
  config = { ...config, setUser, user };
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('access_token');
    if (!headers) headers = {};
    if (token) headers.authorization = `Bearer ${token}`;
    if (clientId) headers["x-pre1-client-id"] = clientId;
    return { headers }
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (message.includes("Expired Session") || message.includes("Context creation failed: jwt expired") || message.includes("Authentication Error")) {
          setUser(null);
          localStorage.removeItem("access_token");
          localStorage.removeItem("contact");
        } else {
          alert(message);
        }
        //newrelic.noticeError(new Error(`[Network error]: ${networkError}`));
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`, networkError.message);
    //if (networkError) newrelic.noticeError(new Error(`[Network error]: ${networkError}`));
  });
  const cache = new InMemoryCache();
  const link = from([
    authLink,
    apolloLogger,
    errorLink,
    httpLink
  ]);
  const client = new ApolloClient({
    cache,
    link,
    name: 'subscriberpotal',
    version: '1.0'
  });
  return (
    <AppContext.Provider value={config}>
      <HelmetProvider>
        <Helmet
          titleTemplate={`%s | ${config.publicationName}`}
          defaultTitle="Home"
        >
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="Subscriber Portal" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={config.publicationTwitter} />
          <meta property="og:title" content="The Rock" />
          <meta property="og:type" content="video.movie" />
          <meta property="og:url" content="https://www.imdb.com/title/tt0117500/" />
          <meta property="og:image" content="https://ia.media-imdb.com/images/rock.jpg" />
          <link rel="preconnect" href="https://www.gstatic.com"></link>
          <link rel="preconnect" href="https://www.google.com"></link>
          <link rel="preconnect" href={process.env.NODE_ENV === "development" ? "https://jstest.authorize.net" : "https://js.authorize.net"}></link>
        </Helmet>
        <ApolloProvider client={client}>
          <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey} useRecaptchaNet={true} scriptProps={{ defer: true, async: true }} language="en">
            <Suspense fallback={<div></div>}>
              <Router>
                <Routes>
                  <Route path="/" element={<Layout embed={embed} config={config}/>}>
                    <Route index element={<SubscriptionForm embed={embed} />} />
                    <Route path="login" element={<Login />} />
                    <Route path="account" element={<Account/>} />
                    <Route path="subscribe" element={<SubscriptionForm embed={embed} />}>
                      <Route path=":defaultOfferId" element={<SubscriptionForm embed={embed} />} />
                      <Route path="" element={<SubscriptionForm embed={embed} />} />
                    </Route>
                  </Route>
                </Routes>
              </Router>
            </Suspense>
          </GoogleReCaptchaProvider>
        </ApolloProvider>
      </HelmetProvider>
    </AppContext.Provider>
  );
}

export default App;
