import { useFormContext } from "react-hook-form";

export const CheckoutType = () => {
  const { register, watch, formState: { errors, isSubmitting } } = useFormContext();

  return (
    <div>
      <p className="text-sm leading-5 text-gray-500"></p>
      <fieldset disabled={isSubmitting} className="mt-4">
        <legend className="sr-only">Checkout Type</legend>
        <div className="space-y-4">
          <div key="CheckoutGuest" className="flex items-center">
            <input
              value="CheckoutGuest"
              {...register("checkoutType", { required: true })}
              type="radio"
              title='Checkout as Guest'
              defaultChecked={true}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
            <label htmlFor="CheckoutGuest" className="ml-3 block text-sm font-medium text-gray-700">
              Checkout as a Guest
            </label>
          </div>
          <div key="CheckoutNew" className="flex items-center">
            <input
              value="CheckoutNew"
              title='Checkout as a New Customer'
              {...register("checkoutType", { required: true })}
              type="radio"
              disabled={true}
              defaultChecked={false}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
            <label htmlFor="CheckoutNew" className={`ml-3  block text-sm font-medium ${true ? "text-gray-200" : "text-gray-700"}`}>
              Create a new Subscriber account.
            </label>
          </div>
          {watch("checkoutType") === "CheckoutNew" ? (<div className="ml-7"><label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password for new account
          </label><input
              title='Password for new account'
              {...register("newSubscriberPassword", { required: true })}
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            /></div>) : null}
          <div key="CheckoutExisting" className="flex items-center">
            <input
              title='Checkout as an Existing Customer'
              value="CheckoutExisting"
              {...register("checkoutType", { required: true })}
              type="radio"
              disabled={true}
              defaultChecked={false}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
            <label htmlFor="CheckoutExisting" className={`ml-3  block text-sm font-medium ${true ? "text-gray-200" : "text-gray-700"}`}>
              Checkout as an existing subscriber
            </label>
          </div>
          {watch("checkoutType") === "CheckoutExisting" ? (<div className="ml-7"><label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password for existing account
          </label><input
              title='Password for existing account'
              {...register("existingSubscriberPassword", { required: true })}
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            /></div>) : null}
        </div>
      </fieldset>
    </div>
  )
}
export default CheckoutType;


/*import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const settings = [
  { name: 'Guest Checkout', description: 'Checkout as Guest' },
  { name: 'Existing Subscriber', description: 'Checkout with existing account' },
  { name: 'New Subscriber', description: 'Checkout and create a New Account' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const GuestCheckout = () => {
  const [selected, setSelected] = useState(settings[0])

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">Checkout Type</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
export default GuestCheckout;
*/