import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from '@headlessui/react';
import { classNames } from "./SubscriptionForm";

export const GiftSubscription = () => {
  const { control, formState: { isSubmitting} } = useFormContext();
  return (
    <div className="flex row mt-6">
    <fieldset disabled={isSubmitting} className="">
      
      <Controller
        rules={{ required: false }}
        control={control}
        name="giftSubscription"
        render={({ field }) => {
          const { name, onBlur, onChange, value } = field;
          return (
            <Switch
            checked={value}
            onChange={onChange}
            className={`${
              value ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 mr-2  items-center rounded-full`}
          >
            <span className="sr-only">Gift Subscription</span>
            
            <span
              className={`${
                value ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            ></span>
          </Switch>
          );
        }} />
    </fieldset>
    <div>This subscription is a gift</div>
    </div>

  );
};
